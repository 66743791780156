import React from "react"
import Layout from "./components/Layout";
import { Row, Col } from "react-bootstrap";
// import AniLink from "gatsby-plugin-transition-link/AniLink";
// import Fade from 'react-reveal/Fade';
// import Zoom from 'react-reveal/Zoom';

export default function about() {
    return (
        <Layout>

      <Row id="banner" className="min-100 relative bg-inn"
      style={{backgroundPosition: '100% 50%', backgroundSize: '1000%' }}>
        <Col className="col-md-8 align-left-bottom p-5 mt-5">
          <h1 className="site-title text-light text-shadow">
              about me.
          </h1>
        </Col>
        <Col className="offset-md-4"></Col>
      </Row>
      </Layout>
    )
}
